<template>
  <div
    v-if="pageReady"
    class="container mx-auto justify-content-center d-flex flex-column"
  >
    <PageHeader :title="`${bodyPartName}`" class="text-center mt-3" />
    <div class="container-fluid my-3">
      <!-- both sides selected -->
      <div v-if="bothDirection" class="mt-5">
        <div class="my-3 d-flex">
          <p class="d-inline flex-grow-1">
            Which of {{ bodyPartName }} has normal range of movement?
          </p>

          <BaseCheckboxGroup
            v-model="isNormal.double"
            :options="defaultOptionsForBothDirection"
            :label="`isNormal_double`"
          />
        </div>

        <div v-if="bodyPartName.includes('shoulder')" class="my-3 d-flex">
          <p class="d-inline flex-grow-1">
            Which of {{ bodyPartName }} got signs of previous AC Joint Excision
            surgery?
          </p>
          <BaseCheckboxGroup
            v-model="isExcision.double"
            :options="defaultOptionsForBothDirection"
            :label="`ACJointExcision`"
          />
        </div>

        <DefaultTable
          v-model:table-rows="tableParts"
          class="mx-auto"
          :table-head="tableHeader"
          :direction="selectedTableDirection"
          @change="checkForDefault"
        />

        <div class="my-5">
          <label class="form-label">
            Specific diagnosis findings for Left {{ selectedPart }}:
          </label>
          <textarea v-model="diagnosis.left" class="form-control" rows="2" />
        </div>

        <div class="my-5">
          <label class="form-label">
            Specific diagnosis findings for Right {{ selectedPart }}:
          </label>
          <textarea v-model="diagnosis.right" class="form-control" rows="2" />
        </div>
      </div>

      <div v-else class="mt-5">
        <!-- Defaults -->
        <div class="my-3 d-flex">
          <p class="d-inline flex-grow-1">
            Does the {{ bodyPartName }} have normal range of movement?
          </p>

          <BaseRadioGroup
            v-model="isNormal.single"
            :options="defaultOptions"
            :label="`isNormal_single`"
          />
        </div>

        <!-- Shoulder Only -->
        <div v-if="bodyPartName.includes('shoulder')" class="my-3 d-flex">
          <p class="d-inline flex-grow-1">
            Has the {{ bodyPartName }} got signs of previous AC Joint Excision
            surgery?
          </p>
          <BaseRadioGroup
            v-model="isExcision.single"
            :options="defaultOptions"
            :label="`AC Joint Excision`"
          />
        </div>

        <!-- Radio option for opposite site -->
        <div class="my-3 d-flex">
          <p class="d-inline flex-grow-1">
            Enable the opposite {{ selectedPart.toLowerCase() }} examination ?
          </p>
          <BaseRadioGroup
            v-model="allowOther"
            :options="defaultOptions"
            :label="`opposite_site`"
          />
        </div>

        <DefaultTable
          v-if="parts.length > 0"
          v-model:table-rows="tableParts"
          class="mx-auto"
          :table-head="tableHeader"
          :direction="selectedTableDirection"
          :body-part="selectedPart"
          @change="checkForDefault"
        />

        <div class="my-5">
          <label class="form-label">
            Specific diagnosis findings for {{ bodyPartName.toLowerCase() }}:
          </label>
          <textarea
            v-model="diagnosis[`${direction}`]"
            class="form-control"
            rows="2"
          />
        </div>
      </div>

      <div class="d-flex justify-content-between py-3">
        <BaseBtn
          :class="`px-3`"
          :color="`outline-danger`"
          :text-color="`danger`"
          :data-bs-toggle="`modal`"
          :data-bs-target="`#confirmationModal`"
        >
          Delete
        </BaseBtn>

        <BaseBtn :class="`px-3`" @click="onSave">Save</BaseBtn>
      </div>
    </div>

    <!-- cancel modal -->
    <div
      id="confirmationModal"
      class="modal fade confirmation-box"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title px-2">Confirmation Box</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body py-4 text-center">
            <p>
              Are you sure to cancel and remove this
              {{ bodyPartName }} selection?
            </p>
            <div
              v-if="existingTableData.direction.length === 2"
              class="mt-5 text-center"
            >
              <p>Please select which side to remove:</p>
              <div
                v-for="side in existingTableData.direction"
                :key="side"
                class="form-check-inline"
              >
                <input
                  v-model="sideToRemove"
                  type="checkbox"
                  :value="side"
                  class="form-check-input"
                />

                <label class="form-label ms-2">
                  {{ side === 'R' ? 'Right' : 'Left' }}
                </label>
              </div>
            </div>
          </div>

          <div class="mb-4 d-flex justify-content-between">
            <button
              type="button"
              class="btn btn-secondary mx-auto text-white"
              data-bs-dismiss="modal"
            >
              Bring me back
            </button>
            <button
              type="button"
              class="btn btn-danger mx-auto text-white"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="removeBodyPart"
            >
              <strong>Yes, remove it!</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/BodyPart/PageHeader.vue'
import DefaultTable from '@/components/Assessment/DefaultAssessmentTable.vue'
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseBtn from '@/components/Base/BaseButton.vue'
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue'

//table field ordering
import FieldOrders from '@/assets/files/ImpairmentTableFieldsOrder.json'

export default {
  name: 'UpperBodyParts',
  components: {
    PageHeader,
    DefaultTable,
    BaseRadioGroup,
    BaseCheckboxGroup,
    BaseBtn,
  },

  props: {
    assessmentId: { type: Number, default: null },
    selectedPart: { type: String, required: true },
    existingTableData: { type: Object, default: null },
    apiPostObj: { type: [Object, Array], default: null },
  },

  emits: ['update:readyNext', 'removeBodyPart'],

  data() {
    return {
      parts: [],
      isExcision: { single: false, double: [] },
      direction: '',
      sideToRemove: [],
      diagnosis: {},
      allowOther: false,
      pageReady: false,
      defaultOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      defaultOptionsForBothDirection: ['Right', 'Left'],
      isNormal: { single: false, double: [] },
    }
  },

  computed: {
    bothDirection: function () {
      if (
        this.existingTableData.direction.includes('L') &&
        this.existingTableData.direction.includes('R')
      ) {
        return true
      } else {
        //set single direction's text display
        this.checkDirection()

        return false
      }
    },

    selectedTableDirection() {
      let final = []

      if (this.bothDirection) {
        return (final = ['right', 'left'])
      } else {
        final.push(this.direction)

        if (this.allowOther) {
          //get the opposite direction
          let oppo = this.direction === 'left' ? 'right' : 'left'

          final.push(oppo)
        }

        return final
      }
    },

    tableHeader: function () {
      let header = ['Movement Type', this.bodyPartName]

      if (this.bothDirection || this.allowOther) {
        return (header = [
          'Movement Type',
          `Right ${this.selectedPart} `,
          `Left ${this.selectedPart}`,
        ])
      }

      return header
    },

    // TODO: Maybe add display name field into backend and frontend to remap

    tableParts: function () {
      if (this.bothDirection || this.allowOther) {
        this.isNormal.double.includes('Right')
          ? this.parts.forEach(
              (x) => (x.right.degree_id = x.right.normal_degree_id)
            )
          : ''
        this.isNormal.double.includes('Left')
          ? this.parts.forEach(
              (x) => (x.left.degree_id = x.left.normal_degree_id)
            )
          : ''
      } else if (this.isNormal.single) {
        this.parts.forEach((x) => {
          x[this.direction].degree_id = x[this.direction].normal_degree_id
        })
      }
      return this.parts
    },

    bodyPartName() {
      let result = this.selectedPart.toLowerCase()

      //special cases
      if (this.selectedPart === 'finger_thumb') {
        result = 'thumb'
      }

      //determine direction
      if (this.bothDirection) {
        return 'Right & Left ' + result
      } else return this.direction + ' ' + result
    },
  },

  async mounted() {
    this.mapBodyPartData(this.apiPostObj)

    // Map existing diagnosis

    this.apiPostObj.forEach((x) =>
      x.is_selected
        ? x.direction === 'L'
          ? (this.diagnosis.left = x.specific_diagnosis)
          : x.direction === 'R'
          ? (this.diagnosis.right = x.specific_diagnosis)
          : ''
        : ''
    )

    this.pageReady = true
  },

  methods: {
    //add event call to detect if selected non default values to untick the default selected or not question
    checkForDefault(val) {
      if (this.bothDirection || this.allowOther) {
        val.value.degree_id === val.value.normal_degree_id
          ? ''
          : (this.isNormal.double = this.isNormal.double.filter(
              (x) => x !== val.direction
            ))
      } else {
        val.value.degree_id === val.value.normal_degree_id
          ? ''
          : (this.isNormal.single = false)
      }
    },

    removeBodyPart() {
      // Emit the body part's name and direction to be removed
      let arrayOfBodyPart = []

      let obj = {
        name: this.existingTableData.name,
        directions: this.existingTableData.direction,
      }

      // When both side are selected, only removed what user selects
      if (this.existingTableData.direction.length === 1) {
        arrayOfBodyPart.push(obj)
      } else {
        obj.directions = this.sideToRemove
        arrayOfBodyPart.push(obj)
      }

      this.$emit('removeBodyPart', arrayOfBodyPart)
    },

    checkDirection() {
      this.direction = this.existingTableData.direction.includes('L')
        ? 'left'
        : 'right'
    },

    mapBodyPartData(data) {
      //sort table fields first
      const sortByObject = FieldOrders[this.selectedPart.toLowerCase()].reduce(
        (obj, item, index) => {
          return {
            ...obj,
            [item]: index,
          }
        },
        {}
      )

      let sortedTable = this.existingTableData.fields

      sortedTable = sortedTable.sort(
        (a, b) => sortByObject[a.field_name] - sortByObject[b.field_name]
      )

      this.parts = sortedTable.map(
        (own) => (own = { left: {}, right: {}, ...own })
      )

      data.forEach((obj) => {
        if (obj.direction === 'R') {
          this.parts.forEach((part) => {
            let { degree_id, normal_degree_id, field_name, field_type, id } =
              obj.fields.find((field) => {
                return field.field_name === part.field_name
              })
            part.right = {
              id,
              degree_id,
              normal_degree_id,
              field_name,
              field_type,
            }
          })
        } else {
          this.parts.forEach((part) => {
            let { degree_id, normal_degree_id, field_name, field_type, id } =
              obj.fields.find((field) => {
                return field.field_name === part.field_name
              })
            part.left = {
              id,
              degree_id,
              normal_degree_id,
              field_name,
              field_type,
            }
          })
        }
      })

      //for shoulder , map the radio input
      if (this.existingTableData.name === 'shoulder') {
        if (this.bothDirection) {
          this.apiPostObj.forEach((x) => {
            let ac = x.fields.find(
              (field) => field.field_name === 'ac_joint_excision'
            )

            ac.value === 'yes'
              ? x.direction === 'L'
                ? this.isExcision.double.push('left')
                : x.direction === 'R'
                ? this.isExcision.double.push('right')
                : ''
              : ''
          })
        } else {
          let selected = this.apiPostObj.find((x) => x.is_selected === true)
          this.isExcision.single =
            selected.fields.find((e) => e.field_name === 'ac_joint_excision')
              .value === 'yes'
              ? true
              : false
        }
      }

      //map default of examination

      //if detect any non default value, add that field value id into array of respective sides
      let foundLeft = []
      let foundRight = []

      this.parts.forEach((part) => {
        part.left.degree_id === part.left.normal_degree_id ||
        part.left.degree_id === null
          ? ''
          : foundLeft.push(part.left.id)

        part.right.degree_id === part.right.normal_degree_id ||
        part.right.degree_id === null
          ? ''
          : foundRight.push(part.right.id)
      })

      //determine the initial checkbox or radiobox isDefault input value
      if (foundLeft.length === 0 && foundRight.length === 0) {
        this.isNormal.double = ['Right', 'Left']
        this.isNormal.single = true
      } else if (foundLeft.length === 0) {
        this.isNormal.double = ['Left']
        this.isNormal.single = true
      } else if (foundRight.length === 0) {
        this.isNormal.double = ['Right']
        this.isNormal.single = true
      } else {
        this.isNormal.double = []
        this.isNormal.single = false
      }

      //map default of opposite site
      if (this.selectedTableDirection.length === 1) {
        //get the opposite direction
        let oppo = this.direction === 'left' ? 'R' : 'L'

        //if opposite side has values
        let checkForNulls = this.apiPostObj
          .find((x) => x.direction === oppo)
          .fields.every((field) => {
            return field.degree_id === null || field.value === ('no' || '')
          })

        if (!checkForNulls) {
          return (this.allowOther = true)
        }
      }

      // this.insertDefaults()

      return this.parts
    },

    async onSave() {
      let PAYLOAD = {}

      PAYLOAD.id = this.assessmentId

      // get opposite of direction

      let left = []
      let right = []
      this.parts.forEach((part) => {
        let def = part.default
        if (part.left.degree_id === null) {
          part.left.degree_id = def
          left.push(part.left)
        } else {
          left.push(part.left)
        }

        if (part.right.degree_id === null) {
          part.right.degree_id = def
          right.push(part.right)
        } else {
          right.push(part.right)
        }
      })

      //shoulder excision only

      if (this.bodyPartName.includes('shoulder')) {
        let objR = this.apiPostObj
          .find((x) => x.direction === 'R')
          .fields.find((x) => x.field_name === 'ac_joint_excision')
        let objL = this.apiPostObj
          .find((x) => x.direction === 'L')
          .fields.find((x) => x.field_name === 'ac_joint_excision')

        if (this.isExcision.single) {
          //update the shoulder exision of single value

          let selected = this.apiPostObj
            .find((x) => x.is_selected)
            .fields.find((x) => x.field_name === 'ac_joint_excision')

          selected.value = 'yes'

          this.direction === 'left' ? left.push(selected) : right.push(selected)
        } else {
          if (this.isExcision.double.includes('Right')) {
            objR.value = 'yes'
            right.push(objR)
          } else {
            objR.value = 'no'
            right.push(objR)
          }

          if (this.isExcision.double.includes('Left')) {
            objL.value = 'yes'
            left.push(objL)
          } else {
            objL.value = 'no'
            left.push(objL)
          }
        }
      }

      this.apiPostObj.forEach((x) => {
        // Map input data based on directions
        if (x.direction === 'L' && left.length > 0) {
          x.fields = left
          x.specific_diagnosis = this.diagnosis.left
        }

        if (x.direction === 'R' && right.length > 0) {
          x.fields = right
          x.specific_diagnosis = this.diagnosis.right
        }
      })

      //map specific diagnosis

      PAYLOAD.payload = this.apiPostObj

      await this.$store
        .dispatch(`putNewBodyPartToAssessment`, PAYLOAD)
        .then(() => {
          let buttonEnable = true
          this.$emit('update:readyNext', buttonEnable)

          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Successfully updated assessed ${this.bodyPartName}'s data`,
          })
        })
        .catch((error) => {
          console.log(error)
          // Trigger toast text
          this.$root.toasts.push({
            status: 'danger',
            content: 'Something went wrong, please inform the development team. '`ERROR: ${error}`,
          })
        })
    },
  },
}
</script>

<style lang="scss"></style>
