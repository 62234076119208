<template>
  <div>
    <table class="impairex-table">
      <thead class="table-primary text-white">
        <tr>
          <th
            v-for="(item, index) in tableHead"
            :key="index"
            scope="col"
            class="text-capitalize"
          >
            {{ item }}
          </th>
        </tr>
      </thead>

      <tbody class="table-white">
        <tr v-for="(part, index) in tableBody" :key="index" class="table-light">
          <th scope="row" class="text-capitalize">
            {{ part.display_name }}
          </th>

          <td v-if="direction && direction.includes('right')" class="pt-4">
            <select
              v-model="part.right.degree_id"
              class="w-75 form-select"
              @change="emitParts('Right', part.right)"
            >
              <option selected disable hidden />
              <option
                v-for="option in part.degrees"
                :key="option.degree"
                :value="option.id"
              >
                <span
                  v-if="
                    part.field_name === 'cmc_adduction' ||
                    part.field_name === 'cmc_opposition'
                  "
                >
                  {{ option.degree }} cm
                </span>
                <span v-else>{{ option.degree }}°</span>
              </option>
            </select>
          </td>

          <td v-if="direction && direction.includes('left')" class="pt-4">
            <select
              v-model="part.left.degree_id"
              class="w-75 form-select"
              @change="emitParts('Left', part.left)"
            >
              <option selected disable hidden />
              <option
                v-for="option in part.degrees"
                :key="option.degree"
                :value="option.id"
              >
                <span
                  v-if="
                    part.field_name === 'cmc_adduction' ||
                    part.field_name === 'cmc_opposition'
                  "
                >
                  {{ option.degree }} cm
                </span>
                <span v-else>{{ option.degree }}°</span>
              </option>
            </select>
          </td>

          <!-- directonless is for spine -->
          <td v-if="!direction" class="pt-4">
            <select v-model="part.degree_id" class="w-75 form-select">
              <option selected disable hidden />
              <option
                v-for="option in part.degrees"
                :key="option.degree"
                :value="option.id"
              >
                <span>{{ option.degree }}°</span>
              </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DefaultAssessmentTable',
  components: {},
  props: {
    tableHead: {
      type: [Array, Object],
      required: true,
    },

    direction: {
      type: Array,
      default: null,
    },
    tableRows: { type: [Array, Object], required: true, default: null },
    caption: { type: String, default: null },
    bodyPart: { type: String, default: null },
  },

  emits: ['update:tableRows', 'change'],

  computed: {
    tableBody() {
      if (this.bodyPart === 'Thumb') {
        return this.tableRows.map((row) => {
          // Ensure Ip into IP, Mp into MP, Cmc into CMC for thumb

          let displayNameAry = row.field_name.split('_')

          displayNameAry[0] = displayNameAry[0].toUpperCase()

          // Thumb display names are more special than other parts

          if (displayNameAry.join(' ') === 'CMC adduction') {
            row['display_name'] = 'CMC Lack of Adduction (←)'
          } else row['display_name'] = displayNameAry.join(' ')

          return row
        })
      } else
        return this.tableRows.map((row) => {
          // This is for the non thumb body part
          row['display_name'] = row.field_name.replace('_', ' ')

          return row
        })
    },
  },

  methods: {
    emitParts(direction, value) {
      let final = {
        direction,
        value,
      }
      this.$emit('change', final)
    },
  },
}
</script>

<style></style>
